// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  useToast,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import {
  InvalidPasswordException,
  UsernameExistsException,
} from "@aws-sdk/client-cognito-identity-provider";
// Assets
import React, { useState } from "react";
import { signUp } from "services/authService";
import NewpasswordInput from "components/PasswordInput/passwordInput";
import EmailInput from "components/Emailinput/emailInput";
import { set } from "date-fns";
import { FaArrowLeft } from "react-icons/fa";
import config from "../../../config.json";
import {getCompanyName, getCoverImage} from "../../../extensions/stylingExstension"
function SignUpCover() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signUpErrorPassword, setSignUpErrorPassword] = useState("");
  const [signUpErrorEmail, setSignUpErrorEmail] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [canProceed, setCanProceed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const toast = useToast();
  const companyName = getCompanyName();



  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const response = await signUp(email, password);
      toast({
        title: "Account aangemaakt",
        description:
          "U heeft succesvol een account aangemaakt, u wordt doorgestuurd naar de inlogpagina",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = "/sign-in";
      }, 4000);
    } catch (error) {
      if (error instanceof InvalidPasswordException) {
        setSignUpErrorPassword("Het wachtwoord voldoet niet aan de eisen");
      } else if (error instanceof UsernameExistsException) {
        setSignUpErrorEmail("U heeft al een account");
      } else {
        setSignUpError(
            `Er is iets fout gegaan tijdens het aanmaken van een account neem contact op met '${companyName}'`,
        );
      }
    }
  };

  const handleNavigation = () => {
    window.location.href = "/sign-in"; // doorgaan naar de inlogpagina
  };

  const handlePasswordChange = (password, isValid) => {
    setPassword(password); // Wachtwoord van child naar parent hier instellen
    setCanProceed(isValid && email); // Als het wachtwoord geldig is dan kan de gebruiker verder
  };

  const handleEmailChange = (newEmail, isValid) => {
    setEmail(newEmail);
    setCanProceed(isValid && password);
  };

  getCompanyName();

  return (
    <Flex position="relative" data-theme={config.style}>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color="var(--title-color)" fontSize="32px" mb="10px">
              Welkom bij het {companyName} portaal.
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color="var(--text-color)"
              fontWeight="bold"
              fontSize="14px"
            >
              Voer je e-mailadres en wachtwoord in om je aan te melden voor het
              portaal.
            </Text>
            <Flex position="absolute" top="100px" left="40px" zIndex="10">
              <Tooltip
                hasArrow
                label="Ga terug"
                bg="var(--back-button)"
                color="var(--primary-button-text)"
              >
                <IconButton
                  icon={<FaArrowLeft />}
                  aria-label="Terug"
                  color="var(--icon-color)"
                  borderRadius="50%"
                  boxSize="40px"
                  bg="var(--back-button)"
                  _hover="var(--back-button-hover)"
                  onClick={() => (window.location.href = "/auth/sign-in")}
                />
              </Tooltip>
            </Flex>
            <form>
              <FormControl isInvalid={signUpError}>
                <FormControl isInvalid={signUpErrorEmail}>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Email
                  </FormLabel>
                  <EmailInput
                    email={email}
                    onEmailChange={handleEmailChange}
                    showValidationEmail={true}
                  />
                  <FormErrorMessage mb="10px" fontSize="md">
                    {signUpErrorEmail}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={signUpErrorPassword}>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Wachtwoord
                  </FormLabel>
                  <NewpasswordInput
                    password={password}
                    onPasswordChange={handlePasswordChange}
                    showValidation={true}
                  />
                  <FormErrorMessage mb="10px" fontSize="md">
                    {signUpErrorPassword}
                  </FormErrorMessage>
                </FormControl>
                <FormControl display="flex" alignItems="center"></FormControl>
              </FormControl>
              <Button
                fontSize="sm"
                type="submit"
                bg="var(--primary-button)"
                w="100%"
                h="45"
                mb="20px"
                color="var(--primary-button-text)"
                mt="20px"
                onClick={handleSignUp}
                isDisabled={!canProceed}
                _hover={{
                  bg: "var(--primary-button-hover)",
                }}
                _active={{
                  bg: "var(--primary-button-hover)",
                }}
              >
                AANMELDEN
              </Button>
            </form>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color="var(--text-color)" fontWeight="medium">
                Heeft u al een account?
                <Link
                  onClick={handleNavigation}
                  color="var(--title-color)"
                  as="span"
                  ms="5px"
                  fontWeight="bold"
                >
                  Inloggen
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
              bgImage={getCoverImage()}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignUpCover;
