import React from "react";
import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  Badge,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { changelogData as updates } from "./changelogData";
import config from "../../../config.json";

function Changelog() {
  const accordionButtonBg = useColorModeValue("gray.100", "gray.600");

  const badgeColors = {
    Experimenteel: "purple",
    Success: "green",
    Removed: "red",
    Default: "gray",
  };

  return (
    <Container maxW="6xl" pt={{ base: "100px", md: "75px" }} px={{ base: "4", md: "8" }} data-theme={config.style}>
      <Heading mb={8} textAlign="center">
        Changelog
      </Heading>
      <Accordion allowMultiple>
        {updates.map((entry, index) => (
          <AccordionItem key={index} border="none" mb={4}>
            <Box
              borderWidth="1px"
              borderRadius="md"
              overflow="hidden"
              bg="var(--back-button)"
              boxShadow="md"
            >
              <h2>
                <AccordionButton _expanded={{ bg: "var(--back-button)" }}>
                  <Box flex="1" textAlign="left">
                    <Heading size="md">Versie {entry.version}</Heading>
                    <Text fontSize="sm" color="gray.500">
                      {format(new Date(entry.date), "dd MMMM yyyy")}
                    </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={4}>
                  {entry.changes.map((change, i) => (
                    <Stack
                      key={i}
                      direction={{ base: "column", md: "row" }}
                      align="center"
                      justify="space-between"
                      spacing={4}
                    >
                      <Text fontSize="md">{change.text}</Text>
                      {change.type && (
                        <Badge
                          colorScheme={badgeColors[change.type] || "gray"}
                          minW="80px"
                          textAlign="center"
                        >
                          {change.type}
                        </Badge>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </AccordionPanel>
            </Box>
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  );
}

export default Changelog;
