import {
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Globe from "components/Globe/Globe";
import React from "react";
import { getUserName } from "extensions/userInfoExtension";
import ConversationList from "views/Ecommerce/Orders/ConversationList";
import config from "../../../config.json";
import { getCompanyName } from "../../../extensions/stylingExstension"


export default function Default() {
  const companyName = getCompanyName();

  const username = getUserName();

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }} w="100%" maxW="100vw" position="relative" data-theme={config.style}>
      <Text
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
          {companyName} | Notulenbeheer
      </Text>
      <Box
        position="absolute"
        top="0"
        right="0"
        width="40%"
        height="100%"
        zIndex="-1" 
        display={{ base: "none", lg: "block" }}
      >
        <Globe />
      </Box>
      <Flex
        direction="column"
        w="100%"
        maxW="100%"
        gap="32px"
        zIndex="0" 
      >
        <Box
          w={{ base: "100%", lg: "90%" }} 
          overflowX="auto" 
        >
          <ConversationList />
        </Box>
        {/* <Stack
          direction="column"
          spacing="24px"
          w="100%"
          mb="24px"
          maxW="100%"
          zIndex="0"
        > */}
          {/* <FullScreenOverlay></FullScreenOverlay> */}
        {/* </Stack> */}
      </Flex> {/* Dit sluit de Flex container correct af in plaats van Grid */}
    </Flex>
  );
}
