import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {
    Route,
    Switch,
    Redirect,
    useHistory,
    Navigate,
} from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import {BrowserRouter} from "react-router-dom/cjs/react-router-dom.min";
import {jwtDecode} from "jwt-decode";
import {refreshAccessToken} from "services/authService";
import {injectContentsquareScript} from "@contentsquare/tag-sdk";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {exchangeAuthorizationCodeForTokens} from "services/authService";
import config from "config.json";
import {getCompanyName, getFavIcon} from "./extensions/stylingExstension";

injectContentsquareScript({
    siteId: "5196251",
    async: true,
    defer: false,
    onLoad: () => {
        // Initialize Hotjar using Contentsquare SDK
        window.cs("Hotjar", "initialize", {
            hotjarId: 5196251, // Replace with your Hotjar Site ID
            hotjarSnippetVersion: 6, // Typically version 6
        });
    },
});

const isAuthenticated = () => {
    const accessToken = localStorage.getItem("accessToken");
    const decodedToken = accessToken ? jwtDecode(accessToken) : null;
    const currentDate = new Date();

    if (decodedToken && decodedToken.exp * 1000 > currentDate.getTime()) {
        return true;
    }

    return false;
};

const SignInIdp = () => {
    const history = useHistory();
    const [isProcessing, setIsProcessing] = useState(true);
    const [authError, setAuthError] = useState(null);

    useEffect(() => {
        const handleAuthCode = async () => {
            const url = new URL(window.location.href);
            const urlSearchParam = new URLSearchParams(url.search);

            if (urlSearchParam.has("code")) {
                const authorizationCode = urlSearchParam.get("code");
                const callbackUrl = `${location.protocol}//${location.host}${location.pathname}`;

                try {
                    await exchangeAuthorizationCodeForTokens(
                        authorizationCode,
                        callbackUrl
                    );
                    setIsProcessing(false);
                    history.push("/auth/sign-in");
                } catch (err) {
                    console.error("Token exchange failed:", err);
                    setAuthError(err.message || "Failed to exchange authorization code.");
                    setIsProcessing(false); // Done processing
                }
            } else {
                setIsProcessing(false); // No auth code; stop processing
            }
        };

        handleAuthCode();
    }, [history]);

    // Show a loading or error state while processing
    if (isProcessing) {
        return <div>Signing you in...</div>;
    }

    if (authError) {
        return (
            <div>
                <h3>Error Signing In</h3>
                <p>{authError}</p>
                <Redirect to="/auth/sign-in"/>
            </div>
        );
    }

    // Check authentication status after processing
    return isAuthenticated() ? (
        <Redirect to="/admin/dashboard/notulen"/>
    ) : (
        <Redirect to="/auth/sign-in"/>
    );
};

const App = () => {
    useEffect(() => {
        document.documentElement.setAttribute(
          "data-theme",
          config.style
        );
        const checkAndRefreshToken = async () => {
            const accessToken = localStorage.getItem("accessToken");
            const refreshToken =
                localStorage.getItem("refreshToken") ||
                sessionStorage.getItem("refreshToken");

            if (accessToken && refreshToken) {
                const decodedToken = jwtDecode(accessToken);
                const currentTime = Date.now() / 1000;

                // Als het token bijna verlopen is (binnen 5 minuten), vernieuw het dan
                if (decodedToken.exp < currentTime + 5 * 60) {
                    try {
                        await refreshAccessToken(refreshToken);
                    } catch (error) {
                        console.error("Error refreshing token:", error);
                    }
                }
            }
        };

        checkAndRefreshToken();
        const interval = setInterval(checkAndRefreshToken, 3 * 60 * 1000); // Check elke 5 minuten

        return () => clearInterval(interval);
    }, []);


    const updateFavicon = () => {
        const faviconPath = getFavIcon();
        let link = document.querySelector("link[rel='shortcut icon']");

        if (!link) {
            link = document.createElement("link");
            link.rel = "shortcut icon";
            document.head.appendChild(link);
        }
        link.href = `${process.env.PUBLIC_URL}${faviconPath}`;
    }

    const updateTitle = () => {
        const newTitle = getCompanyName();
        let titleElement = document.querySelector("title");

        if (!titleElement) {
            titleElement = document.createElement("title");
            document.head.appendChild(titleElement);
        }
        titleElement.textContent = `${newTitle} | Portaal`;
    };

    updateFavicon();
    updateTitle();

    return (
        <GoogleOAuthProvider clientId={config.googleClientId}>
            <BrowserRouter>
                <Switch>
                    <Route
                        path={`/auth`}
                        render={(props) => {
                            return isAuthenticated() ? (
                                <Redirect to="/"/>
                            ) : (
                                <Route component={AuthLayout}/>
                            );
                        }}
                    />
                    <Route
                        path={`/admin`}
                        render={(props) => {
                            return isAuthenticated() ? (
                                <Route component={AdminLayout}/>
                            ) : (
                                <Redirect to="/auth/sign-in"/>
                            );
                        }}
                    ></Route>
                    <Route component={SignInIdp}/>
                    <Redirect
                        from={`/`}
                        to={
                            isAuthenticated() ? "/admin/dashboard/notulen" : "/auth/sign-in"
                        }
                    />
                </Switch>
            </BrowserRouter>
        </GoogleOAuthProvider>
    );
};

ReactDOM.render(<App/>, document.getElementById("root"));
