import config from "../config.json";
import {LawDiskLogo, Notallogo} from "../components/Icons/Icons";
import React from "react";
import coverLawdisk from "assets/img/cover-auth.png";
import coverNotal from "assets/img/cover-auth-notal.png";

export const CompanyStyle = Object.freeze({
  LAWDISK: "lawdisk",
  NOTAL: "notal",
});

const validateStyle = () => {
  if (!Object.values(CompanyStyle).includes(config.style)) {
    throw new Error(`Invalid style: ${config.style}`);
  }
};


export const getCompanyName = () => {
  validateStyle();

  const companyNameMap = {
    [CompanyStyle.LAWDISK]: "LawDisk™",
    [CompanyStyle.NOTAL]: "Notal",
  };

  return companyNameMap[config.style];
};

export const enableAlex = () => {
  validateStyle();

  const companyNameMap = {
    [CompanyStyle.LAWDISK]: true,
    [CompanyStyle.NOTAL]: false,
  };

  return companyNameMap[config.style];
}


export const getCompanyLogoAsSVG = (width = "32px", height = "32px") => {
  validateStyle();

  const logoMap = {
    [CompanyStyle.LAWDISK]: <LawDiskLogo w={width} h={height} me="10px"/>,
    [CompanyStyle.NOTAL]: <Notallogo w={width} h={height} me="10px"/>,
  };

  return logoMap[config.style];
};


export const getFavIcon = () => {
  validateStyle();

  const faviconMap = {
    [CompanyStyle.LAWDISK]: "/favicon.ico",
    [CompanyStyle.NOTAL]: "/faviconNotal.ico",
  };

  return faviconMap[config.style];
};

export const getWebsiteUrl = () => {
  validateStyle();

  const websiteMap = {
    [CompanyStyle.LAWDISK]: "https://www.lawdisk.nl",
    [CompanyStyle.NOTAL]: "https://www.notal.nl",
  }

  return websiteMap[config.style];
}

export const getCoverImage = () => {
  validateStyle();

  const coverImageMap = {
    [CompanyStyle.LAWDISK]: coverLawdisk,
    [CompanyStyle.NOTAL]: coverNotal,
  }

  return coverImageMap[config.style];
}