// import  
// To be changed
// import Tables from "views/Dashboard/Tables.js";
import {
  DocumentIcon,
  HomeIcon,
  PersonIcon,
} from "components/Icons/Icons";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";

// dashboard imports
import Default from "views/Dashboard/Default";
import NotulenDetail from "views/Dashboard/NotulenDetail";
import Changelog from "views/Dashboard/Changelog";
import SignUpCover from "views/Authentication/SignUp/SignUpCover";
import aLex from "views/Dashboard/aLex"


const dashRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Notulen",
        path: "/dashboard/notulen",
        component: Default,
        layout: "/admin",
        collapese:true,
      },
      {
        name: "Changelog",
        path: "/dashboard/changelog",
        component: Changelog,
        layout: "/admin",
      },
      {
        name: "Detail",
        path: "/dashboard/detail",
        component: NotulenDetail,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Authentication",
    path: "/authentication",
    icon: <PersonIcon color="var(--title-color)" />,
    collapse: true,
    items: [
      {
        name: "Sign In",
        path: "/authentication/sign-in",
        collapse: true,
        authIcon: <DocumentIcon color="var(--title-color)" />,
        items: [
          {
            name: "Cover",
            component: SignInCover,
            path: "/sign-in",
            layout: "/auth",
          },
          {
            name: "Cover",
            component: SignUpCover,
            path: "/sign-up",
            layout: "/auth",
          },
        ],
      },
    ],
  },
];

export default dashRoutes;
