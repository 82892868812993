// OrderList.js
import { Box, Flex, Text, useColorModeValue} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import {
  columnsConversationOverViewMobile,
  columnsConversationOverView,
} from "variables/columnsData";
import { getConversations } from "api/lawdiskService";
import ConversationsTable from "components/Tables/ConversationsTable";
import RecordingButton from "components/Recording/RecordingButton";
// import FileUploadCard from "components/Card/FileUploadCard";
import RecordingContext from "contexts/RecordingContext";
import SettingsContext from "contexts/SettingsContext";

function OrderList() {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const [isLoaded, setIsLoaded] = useState(false);
  const [conversations, setConversations] = useState([]);
  const { conversation, buffer, isRecording } = useContext(RecordingContext);
  const { liveTranscriptionEnabled } = useContext(SettingsContext);

  const fetchConversations = async () => {
    try {
      const conversations = await getConversations();
      setConversations(conversations);
      setIsLoaded(true);
    } catch (error) {
      console.error("Error bij het ophalen van conversaties:", error);
      setConversations([]);
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  const scrollRef = useRef(null);

  // Auto-scroll to bottom when conversation or buffer changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [conversation, buffer]);


  return (
    <Flex direction="column" pt={{ sm: "0px", md: "20px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        justify="space-between"
        align="center"
        w="100%"
      >
        <RecordingButton
          onStopRecording={async () => {
            fetchConversations();
            await new Promise((resolve) => setTimeout(resolve, 5000));
            fetchConversations();
          }}
        />
        {/* <FileUploadCard /> */}
      </Flex>

      {/* Display the conversation during recording */}
      {(buffer || conversation) && isRecording && liveTranscriptionEnabled && (
        <Card mt="20px">
          <CardBody>
            <Box
              ref={scrollRef}
              mt="10px"
              maxHeight="200px" // Fixed height; adjust as needed
              overflowY="auto" // Enable vertical scrolling
              p={4} // Padding for better readability
              border="1px solid"
              // borderColor="gray.200"
              borderRadius="md"
              bg="var(--transcript--bg)" // Light background for contrast
              mx="auto" // Centers the Box horizontally
              boxShadow="sm" // Optional: Add a subtle shadow
              position="relative" // To ensure proper positioning
            >
              <Text whiteSpace="pre-wrap">{conversation + " " + buffer}</Text>
            </Box>
          </CardBody>
        </Card>
      )}

      <Card px="0px" mt="20px">
        <CardBody>
          <ConversationsTable
            tableData={conversations}
            columnsData={
              isMobile
                ? columnsConversationOverViewMobile
                : columnsConversationOverView
            }
            dataLoaded={isLoaded}
            onClickDelete={fetchConversations}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}

export default OrderList;
